import axios from '../utils/axios';

export const fetchProducts = (query = '', start = 0, sortType = null) => {
  let url = query !== '' ? `/products?_q=${query}&serialNumber_contains=${query}&_sort=createdAt:DESC&_limit=10&_start=${start}` : `/products?_sort=createdAt:DESC&_limit=10&_start=${start}`;

  if (sortType) {
    url = query !== '' ? `/products?_q=${query}&serialNumber_contains=${query}&_sort=createdAt:${sortType}&_limit=10&_start=${start}` : `/products?_sort=createdAt:${sortType}&_limit=10&_start=${start}`;
  }
  return axios.get(url);
};

export const fetchProductsCount = (query = '') => {
  let url = query !== '' ? `/products/count?_q=${query}&_sort=createdAt:DESC&_limit=10` : `/products/count?_sort=createdAt:DESC&_limit=10`;
  return axios.get(url);
};

export const fetchAllProducts = () => {
  return axios.get('/products?_sort=createdAt:DESC&_limit=500');
};

export const fetchSingleProduct = (id) => {
  return axios.get(`/products/${id}`);
};

export const addProduct = (data) => {
  return axios.post('/products', data);
};

export const editProduct = (data, id) => {
  return axios.put(`/products/${id}`, data);
};

export const deleteProduct = (id) => {
  return axios.delete(`/products/${id}`);
};

export const fetchBrands = () => {
  return axios.get(`/brands`);
};

export const editProductVariant = (count, parentId, id) => {
  return axios.put(process.env.NODE_ENV === 'production' ? `https://snb-bizi.ir/v1/products/variant/${parentId}/${id}` : `http://localhost:4831/v1/products/variant/${parentId}/${id}`, count);
};

export const addAttribute = (data) => {
  return axios.post(`/attributes`, data);
};

export const updateAttribute = (data, id) => {
  return axios.put(`/attributes/${id}`, data);
};

export const deleteAttribute = (id) => {
  return axios.delete(`/attributes/${id}`);
};

export const fetchAttribute = (id) => {
  return axios.get(`/attributes/${id}`);
};
