import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Button, Select, Notify } from 'zent';
import { withBaseIcon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';

import { fetchMostSailedProduct, fetchMostSailedProductByCount } from '../../services/warehouselogService';
import { fetchAllProducts } from '../../services/productService';
import { fetchTotalSailedProductById } from '../../services/transactionService';
import { useStateValue } from '../../context/state';

import Block from '../../components/common/block';

const Icon = withBaseIcon({ size: 18, style: { color: '#fff' } });

const Reports = ({ history }) => {
  const [mostSailedProductByCount, setMostSailedProductByCount] = useState([]);
  const [mostSailedProduct, setMostSailedProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [totalProductSailed, setTotalProductSailed] = useState({ total: 0, count: 0 });
  const [selected, setSelected] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    Promise.all([fetchMostSailedProduct(), fetchMostSailedProductByCount(), fetchAllProducts()])
      .then((res) => {
        setMostSailedProductByCount(res[0].data);
        setMostSailedProduct(res[1].data);
        setProducts(res[2].data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const searchProductTotalSailed = async () => {
    if (selected) {
      let result = await fetchTotalSailedProductById(selected.id);
      setTotalProductSailed({ total: result.data.total, count: result.data.count });
    } else {
      return Notify.error(4000, 'هیچ محصولی انتخاب نکرده‌اید.');
    }
  };

  const productInvoices = (sharedItems) => {
    dispatch({
      type: 'updateSharedItems',
      sharedItems,
    });
    return history.push(`/items/`);
  };

  const columns = [
    {
      title: 'ردیف',
      bodyRender: (data, config) => {
        return config.row + 1;
      },
    },
    {
      title: 'نام محصول',
      name: 'name',
    },
    {
      title: 'تعداد سفارش',
      name: 'count',
    },
    {
      title: 'تعداد فاکتور',
      name: 'orderCount',
    },
    {
      title: 'مشاهده فاکتور‌ها',
      bodyRender: (data) => {
        return (
          <div className="table-control__container">
            <Button type="primary" onClick={() => productInvoices(data.orderItems.reverse())}>
              <Icon icon={eye} />
            </Button>
          </div>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: 'ردیف',
      bodyRender: (data, config) => {
        return config.row + 1;
      },
    },
    {
      title: 'نام محصول',
      name: 'name',
    },
    {
      title: 'قیمت',
      bodyRender: (data) => {
        return `${data.totalPrice.toLocaleString('fa')} تومان`;
      },
    },
    {
      title: 'تعداد فاکتور',
      name: 'orderCount',
    },
  ];

  return (
    <Container className="animated fadeIn">
      <Block>
        <div className="row">
          <div className="column">
            <Select
              data={products}
              autoWidth
              optionText="name"
              optionValue="id"
              placeholder="انتخاب محصول"
              emptyText="هیچ آیتمی یافت نشده است."
              onChange={(e, item) => {
                setSelected(item);
              }}
              filter={(item, keyword) => item.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1}
              value={selected}
            />
            <Button className="action-btn" onClick={searchProductTotalSailed}>
              فیلتر
            </Button>
          </div>
          <div className="box">
            <span className="title">مجموع فروش</span>
            <span className="order-status status3">{`${totalProductSailed.total.toLocaleString('fa')} تومان`}</span>
            <span className="count">تعداد: {totalProductSailed.count}</span>
          </div>
        </div>
      </Block>
      <div className="items">
        <h2>بیشترین آمار فروش بر اساس تعداد</h2>
        <Grid columns={columns} datasets={mostSailedProductByCount} loading={isLoading} emptyLabel={'هیچ آیتمی یافت نشده است.'} />
      </div>
      <div className="items">
        <h2>بیشترین آمار فروش بر اساس قیمت</h2>
        <Grid columns={columns2} datasets={mostSailedProduct} loading={isLoading} emptyLabel={'هیچ آیتمی یافت نشده است.'} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .items {
    display: block;
    flex: 1;
    margin-left: 20px;
    &::last-child {
      margin-left: 0;
    }
  }
  h2 {
    margin: 25px 0;
    color: #fff;
    font-size: 2em;
  }
`;

export default Reports;
