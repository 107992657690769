import React, { Component } from 'react';
import { Grid, Notify, Portal, Button, Input, Sweetalert } from 'zent';
import { withBaseIcon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/feather/edit';
import { trash2 } from 'react-icons-kit/feather/trash2';
import { iosInformationOutline } from 'react-icons-kit/ionicons/iosInformationOutline';
import moment from 'jalali-moment';

import { fetchProducts, deleteProduct, fetchProductsCount, addAttribute } from '../../services/productService';
import Block from '../../components/common/block';
import { StateContext } from '../../context/state';

const Icon = withBaseIcon({ size: 20, style: { color: '#fff' } });

const queryStringParse = function (string) {
  let parsed = {};
  if (string != '') {
    string = string.substring(string.indexOf('?') + 1);
    let p1 = string.split('&');
    p1.map(function (value) {
      let params = value.split('=');
      parsed[params[0]] = params[1];
    });
  }
  return parsed;
};

class Products extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
      count: 0,
      selectedAttributes: [],
      showModal: false,
      isLoading: true,
      searchText: '',
      pageInfo: {
        pageSize: 10,
        total: 0,
        current: 0,
        start: 0,
        sortBy: 'desc',
      },
    };
  }

  componentDidMount() {
    let params = queryStringParse(window.location.search);
    this.fetchData(params.query || '', params.page || 0, params.start || 0, params.sortType, true);
  }

  fetchData = (query = '', page = 0, start = 0, sortType = null, firstTime = false) => {
    let { pageInfo } = this.state;
    if (!firstTime) this.configUrlBasedOnParams(query, page, start);
    Promise.all([fetchProducts(query, start, sortType), fetchProductsCount(query, start)])
      .then((res) => {
        this.setState({
          datasets: res[0].data,
          count: res[1].data,
          isLoading: false,
          searchText: query,
          pageInfo: {
            ...pageInfo,
            sortBy: sortType?.toLowerCase() || 'desc',
            total: res[1].data,
            current: Number(page),
            start: start ? Number(start) : 0,
          },
        });
      })
      .catch((err) => Notify.error('در برقراری ارتباط مشکلی به وجود آمده است، مجددا تلاش نمایید.'));
  };

  configUrlBasedOnParams = (query = '', page = 0, start = 0, sortType = null) => {
    const urlParams = new URLSearchParams(window.location.search),
      { history } = this.props;
    if (query) {
      urlParams.set('query', query);
      history.push({ search: urlParams.toString() });
    } else if (!query || query === '') {
      urlParams.delete('query');
      history.push({ search: urlParams.toString() });
    }
    if (page) {
      urlParams.set('page', page);
      history.push({ search: urlParams.toString() });
    } else if (!page || page === 0) {
      urlParams.delete('page');
      history.push({ search: urlParams.toString() });
    }
    if (start) {
      urlParams.set('start', start);
      history.push({ search: urlParams.toString() });
    } else if (!start || start === 0) {
      urlParams.delete('start');
      history.push({ search: urlParams.toString() });
    }
    if (sortType) {
      urlParams.set('sort', sortType);
      history.push({ search: urlParams.toString() });
    } else if (!sortType) {
      urlParams.delete('sort');
      history.push({ search: urlParams.toString() });
    }
  };

  onChange = ({ current, sortType }) => {
    const { pageInfo, searchText } = this.state;
    if (current) {
      return this.setState(
        {
          isLoading: true,
        },
        this.fetchData(searchText, Number(current), (current - 2) * 10 + 10)
      );
    }
    if (sortType) {
      let currentPage = !current ? (pageInfo.current === 0 ? 1 : pageInfo.current) : current;
      let sortValue;
      if (this.state.pageInfo.sortBy === 'desc') {
        sortValue = this.state.pageInfo.sortBy === 'desc' && 'asc';
      }
      if (this.state.pageInfo.sortBy === 'asc') {
        sortValue = this.state.pageInfo.sortBy === 'asc' && 'desc';
      }
      this.setState(
        {
          isLoading: true,
          pageInfo: {
            ...pageInfo,
            sortBy: sortValue,
          },
        },
        this.fetchData(searchText, Number(currentPage), (currentPage - 2) * 10 + 10, sortValue.toUpperCase())
      );
    }
  };

  removeProduct = (id) => {
    Sweetalert.confirm({
      content: `آیا مطمئن به حذف این آیتم هستید؟`,
      title: `توجه`,
      confirmType: 'danger',
      confirmText: `حذف`,
      cancelText: `خیر`,
      onConfirm: () =>
        new Promise((resolve) => {
          deleteProduct(id)
            .then(() => {
              this.fetchData();
              Notify.success('محصول مورد نظر حذف گردید.', 5000);
              return resolve();
            })
            .catch((err) => {
              Notify.error('در برقراری ارتباط مشکلی به وجود آمده است.', 5000);
              return resolve();
            });
        }),
    });
  };

  onPressEnter = (e) => {
    if (e.target.value && e.target.value.trim() !== '') {
      this.setState({ isLoading: true, searchText: e.target.value });
      return this.fetchData(e.target.value, 0, 0);
    } else {
      return this.fetchData('', 0, 0);
    }
  };

  onPressEnterSerialNumber = (e) => {
    if (e.target.value && e.target.value.trim() !== '') {
      this.setState({ isLoading: true, searchText: e.target.value });
      return this.fetchData(e.target.value, 0, 0, 1);
    } else {
      return this.fetchData('', 0, 0);
    }
  };

  renderImage = (array) => {
    if (array.length > 0) {
      return `https://api.snb-bizi.ir${array[0].url}`;
    }
    return '';
  };

  showProductAttributes = (selectedAttributes) => {
    this.setState({ selectedAttributes, showModal: true });
  };

  // searchThroughAttributes = (e) => {
  //   let value = e.target.value;
  // };

  render() {
    const { datasets, count, pageInfo, selectedAttributes, showModal, isLoading } = this.state;
    const { history } = this.props;
    const [{ profile }] = this.context;
    let columns = [];
    if (profile) {
      let result = ['authenticated', 'manager'].find((item) => item === profile.role.type);
      let isAdmin = ['authenticated'].find((item) => item === profile.role.type);
      columns = result
        ? [
            {
              title: 'نام محصول',
              width: '25%',
              bodyRender: (data) => {
                return (
                  <div onClick={() => this.showProductAttributes(data.items)} style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '6px',
                        marginLeft: '10px',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        backgroundImage: `url(${this.renderImage(data.image)})`,
                        backgroundColor: '#ddd',
                        backgroundSize: 'cover',
                      }}></div>
                    <Icon style={{ marginLeft: 5 }} icon={iosInformationOutline} />
                    {data.name}
                  </div>
                );
              },
            },
            {
              title: 'قیمت',
              bodyRender: (data) => {
                return `${Number(data.price).toLocaleString('fa')} تومان`;
              },
            },
            {
              title: 'تاریخ ثبت',
              bodyRender: (data) => {
                return moment(data.createdAt).locale('fa').format('YYYY/M/D - HH:mm');
              },
              needSort: true,
            },
            {
              title: 'تاریخ به روزرسانی',
              bodyRender: (data) => {
                return moment(data.updatedAt).locale('fa').format('YYYY/M/D - HH:mm');
              },
            },
            {
              title: '',
              bodyRender: (data) => {
                return (
                  <div className='table-control__container'>
                    <Button type='primary' onClick={() => history.push(`/product/${data.id}`)}>
                      <Icon icon={edit} />
                    </Button>
                    <Button type='danger' onClick={() => this.removeProduct(data.id)}>
                      <Icon icon={trash2} />
                    </Button>
                  </div>
                );
              },
            },
          ]
        : [
            {
              title: 'نام محصول',
              width: '25%',
              bodyRender: (data) => {
                return (
                  <div onClick={() => this.showProductAttributes(data.attributes)}>
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '6px',
                        marginLeft: '10px',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        backgroundImage: `url(${this.renderImage(data.image)})`,
                        backgroundColor: '#ddd',
                      }}></div>
                    <Icon style={{ marginLeft: 5 }} icon={iosInformationOutline} />
                    {data.name}
                  </div>
                );
              },
            },
            {
              title: 'تاریخ ثبت',
              bodyRender: (data) => {
                return moment(data.createdAt).locale('fa').format('YYYY/M/D - HH:mm');
              },
            },
            {
              title: 'تاریخ به روزرسانی',
              bodyRender: (data) => {
                return moment(data.updatedAt).locale('fa').format('YYYY/M/D - HH:mm');
              },
            },
            {
              title: '',
              bodyRender: (data) => {
                return (
                  <div className='table-control__container'>
                    <Button type='primary' onClick={() => history.push(`/product/${data.id}`)}>
                      <Icon icon={edit} />
                    </Button>
                  </div>
                );
              },
            },
          ];
    }
    const attributes = [
      {
        title: 'شماره سریال',
        name: 'serialNumber',
      },
      {
        title: 'رنگ',
        name: 'color',
      },
      {
        title: 'سایز',
        bodyRender: (data) => {
          return data.size.label;
        },
      },
      {
        title: 'موجودی',
        name: 'count',
      },
      {
        title: '',
      },
    ];
    return (
      <div className='animated fadeIn'>
        <Block>
          <h1 className='title'>فهرست محصولات ({count})</h1>
          <div className='row'>
            <Input onPressEnter={this.onPressEnter} icon='search' placeholder='جستجو ...' />
            <Input onPressEnter={this.onPressEnterSerialNumber} icon='search' placeholder='شماره سریال ...' />
            <Button className='add-btn' onClick={() => history.push('/product/add')}>
              درج محصول
            </Button>
          </div>
        </Block>
        {profile && <Grid pageInfo={pageInfo} columns={columns} datasets={datasets} onChange={this.onChange} emptyLabel={'هیچ محصولی یافت نشده است.'} loading={isLoading} />}
        <Portal
          visible={showModal ? true : false}
          onClose={() => this.setState({ selectedAttributes: [], showModal: false })}
          className='layer'
          style={{ background: 'rgba(0, 0, 0, 0.4)' }}
          useLayerForClickAway
          closeOnClickOutside
          closeOnESC
          blockPageScroll>
          {showModal && (
            <div style={{ minWidth: '50%' }} className='custom-portal__container'>
              {/* <Input
                onPressEnter={this.searchThroughAttributes}
                icon="search"
                placeholder="جستجو ..." 
              /> */}
              <Grid columns={attributes} datasets={selectedAttributes} emptyLabel={'هیچ سفارشی یافت نشده است.'} />
            </div>
          )}
        </Portal>
      </div>
    );
  }
}

export default Products;
