import axios from '../utils/axios';

export const fetchOrders = (query = '', start = 0, status = null, sortType = null, startDateTime = null, endDateTime = null) => {
  let url = query !== '' ? `/orders?_q=${query}&_sort=createdAt:DESC&_limit=10&_start=${start}` : `/orders?_sort=createdAt:DESC&_limit=10&_start=${start}`;
  if (status) {
    url = query !== '' ? `/orders?_q=${query}&_sort=createdAt:DESC&_limit=10&_start=${start}&status=${status}` : `/orders?_sort=createdAt:DESC&_limit=10&_start=${start}&status=${status}`;
  }

  if (sortType) {
    url = query !== '' ? `/orders?_q=${query}&_sort=createdAt:${sortType}&_limit=10&_start=${start}` : `/orders?_sort=createdAt:${sortType}&_limit=10&_start=${start}`;
  }

  if (startDateTime && !endDateTime) {
    url += `&createdAt_gte=${startDateTime}`;
  }

  if (endDateTime && !startDateTime) {
    url += `&createdAt_lte=${endDateTime}`;
  }

  if (startDateTime && endDateTime) {
    url += `&createdAt_gte=${startDateTime}&createdAt_lte=${endDateTime}`;
  }

  return axios.get(url);
};

export const fetchOrdersByDate = (startDateTime, endDateTime) => {
  let url = '/orders?';
  if (startDateTime && !endDateTime) {
    url += `createdAt_gte=${startDateTime}`;
  }

  if (endDateTime && !startDateTime) {
    url += `createdAt_lte=${endDateTime}`;
  }

  if (startDateTime && endDateTime) {
    url += `createdAt_gte=${startDateTime}&createdAt_lte=${endDateTime}`;
  }
  return axios.get(url);
};

export const fetchNotPaidOrders = () => {
  return axios.get(`${process.env.NODE_ENV === 'production' ? `https://snb-bizi.ir/v1` : `http://localhost:4831/v1`}/order/getNotPaidOrders`);
};

export const fetchOrdersCount = (query = '', start = 0, status = null, startDateTime = null, endDateTime = null) => {
  let url = query !== '' ? `/orders/count?_q=${query}&_sort=createdAt:DESC&_limit=10` : `/orders/count?_sort=createdAt:DESC&_limit=10`;
  if (status) {
    url = query !== '' ? `/orders/count?_q=${query}&_sort=createdAt:DESC&_limit=10&status=${status}` : `/orders/count?_sort=createdAt:DESC&_limit=10&status=${status}`;
  }

  if (startDateTime && !endDateTime) {
    url += `&createdAt_gte=${startDateTime}`;
  }

  if (endDateTime && !startDateTime) {
    url += `&createdAt_lte=${endDateTime}`;
  }

  if (startDateTime && endDateTime) {
    url += `&createdAt_gte=${startDateTime}&createdAt_lte=${endDateTime}`;
  }

  url += `&_start=${start}`;

  return axios.get(url);
};

export const fetchSingleOrder = (id) => {
  return axios.get(`/orders/${id}`);
};

export const fetchAllTranscationByOrderId = (array) => {
  return axios.get(`${process.env.NODE_ENV === 'production' ? `https://snb-bizi.ir/v1` : `http://localhost:4831/v1`}/transactions/getTransactionByArrayOrderId/${array}`);
};

export const addOrder = (data) => {
  return axios.post(`/orders`, data);
};

export const editOrder = (data, id) => {
  return axios.put(`/orders/${id}`, data);
};

export const updateItemStatus = (id, data) => {
  return axios.put(`/orders/${id}`, data);
};

export const deleteOrder = (id) => {
  return axios.delete(`/orders/${id}`);
};

export const fetchUsers = () => {
  return axios.get(`/users`);
};
