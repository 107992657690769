export const renderSize = (item) => {
  switch (Number(item)) {
    case 0:
      return 'XXS';
    case 1:
      return 'XS';
    case 2:
      return 'S';
    case 3:
      return 'M';
    case 4:
      return 'L';
    case 5:
      return 'XL';
    case 6:
      return 'XXL';
    case 7:
      return 'XXXL';
    case 8:
      return 'XXXXL';

    case 9:
      return '39';
    case 10:
      return '40';
    case 11:
      return '41';
    case 12:
      return '42';
    case 13:
      return '43';
    case 14:
      return '44';
    case 15:
      return '45';
    case 16:
      return '46';
    case 17:
      return '47';
    case 18:
      return '48';
    case 19:
      return '38';
    case 20:
      return '37';
    default:
      return '';
  }
};

export const sizeArray = () => {
  return [
    { value: 0, text: 'XXS' },
    { value: 1, text: 'XS' },
    { value: 2, text: 'S' },
    { value: 3, text: 'M' },
    { value: 4, text: 'L' },
    { value: 5, text: 'XL' },
    { value: 6, text: 'XXL' },
    { value: 7, text: 'XXXL' },
    { value: 8, text: 'XXXXL' },
    { value: 20, label: '37' },
    { value: 19, label: '38' },
    { value: 9, text: '39' },
    { value: 10, text: '40' },
    { value: 11, text: '41' },
    { value: 12, text: '42' },
    { value: 13, text: '43' },
    { value: 14, text: '44' },
    { value: 15, text: '45' },
    { value: 16, text: '46' },
    { value: 17, text: '47' },
    { value: 18, text: '48' },
  ];
};

export const sizeArrayForSelect = [
  { value: 0, label: 'XXS' },
  { value: 1, label: 'XS' },
  { value: 2, label: 'S' },
  { value: 3, label: 'M' },
  { value: 4, label: 'L' },
  { value: 5, label: 'XL' },
  { value: 6, label: 'XXL' },
  { value: 7, label: 'XXXL' },
  { value: 8, label: 'XXXXL' },
  { value: 20, label: '37' },
  { value: 19, label: '38' },
  { value: 9, label: '39' },
  { value: 10, label: '40' },
  { value: 11, label: '41' },
  { value: 12, label: '42' },
  { value: 13, label: '43' },
  { value: 14, label: '44' },
  { value: 15, label: '45' },
  { value: 16, label: '46' },
  { value: 17, label: '47' },
  { value: 18, label: '48' },
];
