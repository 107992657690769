import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { Grid, Notify, Input, Button, Sweetalert, Portal, Select, Dropdown, DropdownButton, DropdownClickTrigger, DropdownContent, DropdownPosition, Menu } from 'zent';
import { withBaseIcon } from 'react-icons-kit';
import { iosInformationOutline } from 'react-icons-kit/ionicons/iosInformationOutline';
import { printer } from 'react-icons-kit/feather/printer';
import { edit } from 'react-icons-kit/feather/edit';
import { trash2 } from 'react-icons-kit/feather/trash2';
import { shoppingCart } from 'react-icons-kit/feather/shoppingCart';
import moment from 'jalali-moment';
import DatePicker from 'react-datepicker2';

import { fetchOrders, deleteOrder, fetchOrdersCount, fetchUsers, fetchAllTranscationByOrderId, updateItemStatus } from '../../services/orderService';
import { deleteTransactionByOrderId, addTransaction, fetchSingleTransactionByOrderId } from '../../services/transactionService';
import { fetchSingleProduct, editProduct, fetchAttribute, updateAttribute } from '../../services/productService';
import Block from '../../components/common/block';

const Icon = withBaseIcon({ size: 18, style: { color: '#fff' } }),
  enabledRange = {
    max: moment(),
  },
  { MenuItem } = Menu;

const queryStringParse = function (string) {
  let parsed = {};
  if (string != '') {
    string = string.substring(string.indexOf('?') + 1);
    let p1 = string.split('&');
    p1.map(function (value) {
      let params = value.split('=');
      parsed[params[0]] = params[1];
    });
  }
  return parsed;
};

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
      users: [],
      count: 0,
      searchText: '',
      selectedStatus: null,
      orderItems: null,
      isLoading: true,
      pageInfo: {
        pageSize: 10,
        total: 0,
        current: 0,
        start: 0,
        sortBy: 'desc',
      },
      startDateTime: null,
      endDateTime: null,
      visible: false,
    };
  }

  componentDidMount() {
    let params = queryStringParse(window.location.search);
    console.log(params);
    this.fetchData(params.query || '', params.page || 0, params.start || 0, params.status || null, params.sort || null, params.startDateTime || null, params.endDateTime || null, true);
  }

  fetchData = (query = '', page = 0, start = 0, status = null, sortType = null, startDateTime = null, endDateTime = null, firstTime = false) => {
    let { pageInfo } = this.state;
    if (!firstTime) this.configUrlBasedOnParams(query, page, start, status, sortType, startDateTime, endDateTime);
    Promise.all([fetchOrdersCount(query, start, status, startDateTime, endDateTime), fetchOrders(query, start, status, sortType, startDateTime, endDateTime), fetchUsers()])
      .then((res) => {
        this.setState({
          count: res[0].data,
          users: res[2].data,
          datasets: res[1].data,
          isLoading: false,
          searchText: query,
          selectedStatus: status ? Number(status) : null,
          // startDateTime: startDateTime,
          // endDateTime: endDateTime,
          pageInfo: {
            ...pageInfo,
            sortBy: sortType?.toLowerCase() || 'desc',
            total: res[0].data,
            current: Number(page),
            start: start ? Number(start) : 0,
          },
        });
        // this.checkForPaidOrder(res[1].data);
      })
      .catch((err) => Notify.error('در برقراری ارتباط مشکلی به وجود آمده است، مجددا تلاش نمایید.'));
  };

  configUrlBasedOnParams = (query = '', page = 0, start = 0, status = null, sortType = null, startDateTime = null, endDateTime = null) => {
    const urlParams = new URLSearchParams(window.location.search),
      { history } = this.props;
    if (query) {
      urlParams.set('query', query);
      history.push({ search: urlParams.toString() });
    } else if (!query || query === '') {
      urlParams.delete('query');
      history.push({ search: urlParams.toString() });
    }
    if (page) {
      urlParams.set('page', page);
      history.push({ search: urlParams.toString() });
    } else if (!page || page === 0) {
      urlParams.delete('page');
      history.push({ search: urlParams.toString() });
    }
    if (start) {
      urlParams.set('start', start);
      history.push({ search: urlParams.toString() });
    } else if (!start || start === 0) {
      urlParams.delete('start');
      history.push({ search: urlParams.toString() });
    }
    if (status) {
      urlParams.set('status', status);
      history.push({ search: urlParams.toString() });
    } else if (!status) {
      urlParams.delete('status');
      history.push({ search: urlParams.toString() });
    }
    if (sortType) {
      urlParams.set('sort', sortType);
      history.push({ search: urlParams.toString() });
    } else if (!sortType) {
      urlParams.delete('sort');
      history.push({ search: urlParams.toString() });
    }
    if (startDateTime) {
      urlParams.set('startDateTime', startDateTime);
      history.push({ search: urlParams.toString() });
    } else if (!startDateTime) {
      urlParams.delete('startDateTime');
      history.push({ search: urlParams.toString() });
    }
    if (endDateTime) {
      urlParams.set('endDateTime', endDateTime);
      history.push({ search: urlParams.toString() });
    } else if (!endDateTime) {
      urlParams.delete('endDateTime');
      history.push({ search: urlParams.toString() });
    }
  };

  // checkForPaidOrder = async (data) => {
  //   let idCollection = data.map((item) => item.id),
  //     customeData = data;

  //   try {
  //     let findTransactions = await fetchAllTranscationByOrderId(idCollection);
  //     findTransactions.data.forEach((item) => {
  //       customeData.forEach((el) => {
  //         if (el.id === item.orderId && !el.checkoutDate) {
  //           updateItemStatus(el.id, { checkoutDate: item.createdAt });
  //           // return (el['isPaid'] = true);
  //         }
  //       });
  //     });
  //   } catch {}
  // };

  onChange = ({ current, sortType }) => {
    const { selectedStatus, pageInfo, startDateTime, endDateTime, searchText } = this.state;
    if (current) {
      this.setState(
        {
          isLoading: true,
        },
        this.fetchData(searchText, Number(current), (current - 2) * 10 + 10, selectedStatus, startDateTime ? moment(startDateTime).toISOString() : null, endDateTime ? moment(endDateTime).toISOString() : null)
      );
    }
    if (sortType) {
      let currentPage = !current ? (pageInfo.current === 0 ? 1 : pageInfo.current) : current;
      let sortValue;
      if (this.state.pageInfo.sortBy === 'desc') {
        sortValue = this.state.pageInfo.sortBy === 'desc' && 'asc';
      }
      if (this.state.pageInfo.sortBy === 'asc') {
        sortValue = this.state.pageInfo.sortBy === 'asc' && 'desc';
      }
      this.setState(
        {
          isLoading: true,
          pageInfo: {
            ...pageInfo,
            sortBy: sortValue,
          },
        },
        this.fetchData(
          searchText,
          Number(currentPage),
          (currentPage - 2) * 10 + 10,
          selectedStatus,
          sortValue.toUpperCase(),
          startDateTime ? moment(startDateTime).toISOString() : null,
          endDateTime ? moment(endDateTime).toISOString() : null
        )
      );
    }
  };

  removeOrder = (data) => {
    Sweetalert.confirm({
      content: `آیا مطمئن به حذف این سفارش هستید؟`,
      title: `توجه`,
      confirmType: 'danger',
      confirmText: `حذف`,
      cancelText: `خیر`,
      onConfirm: () =>
        new Promise((resolve) => {
          this.setState({ isLoading: true });
          deleteOrder(data.id)
            .then(() => {
              deleteTransactionByOrderId(data.id);
              this.updateProductCount(data.orderItems);
              Notify.success('سفارش مورد نظر حذف گردید.', 5000);
              return resolve();
            })
            .catch((err) => {
              this.setState({ isLoading: false });
              Notify.error('در برقراری ارتباط مشکلی به وجود آمده است.', 5000);
              return resolve();
            });
        }),
    });
  };

  updateProductCount = async (selectedProducts) => {
    selectedProducts.forEach(async (item, index) => {
      let { data } = await fetchAttribute(item.attributeId);
      let count = data.count + item.orderCount;
      await updateAttribute({ count }, data._id);
      if (index + 1 === selectedProducts.length) this.fetchData();
    });
  };

  renderStatus = (status) => {
    switch (Number(status)) {
      case 1:
        return <span className='order-status status1'>ثبت شده</span>;
      case 2:
        return <span className='order-status status2'>پرداخت شده</span>;
      case 3:
        return <span className='order-status status3'>در حال ارسال</span>;
      case 4:
        return <span className='order-status status4'>تحویل داده شده</span>;
      case 5:
        return <span className='order-status status5'>لغو</span>;
      case 6:
        return <span className='order-status status6'>ارسال برای چاپ</span>;
      case 7:
        return <span className='order-status status7'>آماده ارسال</span>;
      case 8:
        return <span className='order-status status8'>تحویل پست</span>;
      default:
        return '';
    }
  };

  onPressEnter = (e) => {
    let { selectedStatus, startDateTime, endDateTime } = this.state;
    this.setState({ isLoading: true, searchText: e.target.value });
    if (e.target.value && e.target.value.trim() !== '') {
      return this.fetchData(e.target.value, 0, 0, selectedStatus, startDateTime, endDateTime);
    }
  };

  renderCourier = (id) => {
    return this.state.users.map((item) => {
      return item.courierId === Number(id) ? item.fullName : '';
    });
  };

  onChangeStatus = (e, item) => {
    this.setState({ selectedStatus: item.id, isLoading: true });
    return this.fetchData(this.state.searchText, 0, 0, item.id, this.state.startDateTime, this.state.endDateTime);
  };

  renderTotalOrderPrice = (data) => {
    let { priceWithDiscount, price, orderCount } = data;
    if (priceWithDiscount && Number(priceWithDiscount) !== 0 && Number(priceWithDiscount) < Number(price)) {
      return (Number(priceWithDiscount) * Number(orderCount)).toLocaleString('fa');
    }
    return (Number(price) * Number(orderCount)).toLocaleString('fa');
  };

  renderOrderStatus = (status) => {
    switch (Number(status)) {
      case 1:
        return 'آنلاین';
      case 2:
        return 'کاستوم';
      case 3:
        return 'اینستاگرام';
      case 4:
        return 'حضوری';
      case 5:
        return 'فروشگاه';
      default:
        return '';
    }
  };

  renderTotalInvoicePrice = (priceWithDiscount, price) => {
    if (price && Number(price) > 0 && Number(price) < Number(priceWithDiscount)) {
      return price;
    }
    return priceWithDiscount;
  };

  addInvoiceToTransaction = async (item) => {
    const { pageInfo } = this.state;
    const { orderStatus, priceWithDiscount, price, fullName, id } = item;
    this.setState({ isLoading: true });
    let result = await fetchSingleTransactionByOrderId(id);
    let currentPage = pageInfo.current === 0 ? 1 : pageInfo.current;
    if (isEmpty(result.data)) {
      Sweetalert.confirm({
        content: `آیا مطمئن به پرداخت این سفارش هستید؟`,
        title: `توجه`,
        confirmType: 'success',
        confirmText: `بله`,
        cancelText: `خیر`,
        onCancel: () => {
          return this.setState({ isLoading: false });
        },
        onConfirm: () =>
          new Promise((resolve) => {
            addTransaction({
              name: this.renderOrderStatus(orderStatus),
              price: this.renderTotalInvoicePrice(priceWithDiscount, price),
              status: 2,
              description: `خرید از طرف ${fullName}`,
              picture: [],
              transactionType: 1,
              cutomeDate: null,
              orderId: id,
            })
              .then(() => {
                updateItemStatus(id, { isPaid: true, checkoutDate: moment().utc().format() });
                this.fetchData(this.state.searchText, currentPage, (currentPage - 2) * 10 + 10, this.state.selectedStatus, this.state.startDateTime, this.state.endDateTime);
                Notify.success('سفارش مورد نظر با موفقیت  به تراکنش مالی اضافه گردید.', 5000);
                return resolve();
              })
              .catch((err) => {
                this.setState({ isLoading: false });
                Notify.error('در برقراری ارتباط مشکلی به وجود آمده است.', 5000);
                return resolve();
              });
          }),
      });
    } else {
      this.setState({ isLoading: false });
      return Notify.error('سفارش مورد نظر از قبل در لیست پرداختی‌ها موجود است.', 5000);
    }
  };

  rowClass = (data) => {
    return data.isPaid ? 'paid-invoice' : null;
  };

  searchByDate = () => {
    const { startDateTime, endDateTime, searchText, pageInfo, selectedStatus } = this.state;
    let sortBy = pageInfo.sortBy;

    this.fetchData(
      searchText,
      pageInfo.current,
      (pageInfo.current === 0 ? 1 : pageInfo.current - 2) * 10 + 10,
      selectedStatus,
      sortBy === 'desc' ? 'ASC' : 'DESC',
      startDateTime ? moment(startDateTime).toISOString() : null,
      endDateTime ? moment(endDateTime).toISOString() : null
    );
  };

  handleMenuButton = (type, data) => {
    let { history } = this.props;
    switch (type) {
      case 'item_0':
        return history.push(`/order/print/${data.id}`);
      case 'item_1':
        return history.push(`/order/${data.id}`);
      case 'item_2':
        return this.removeOrder(data);
      case 'item_3':
        return this.addInvoiceToTransaction(data);
      default:
        return '';
    }
  };

  renderItemName = (item) => {
    return (
      <div className='order-name__container'>
        {item.name} - تعداد فروش: <strong>{item.orderCount}</strong>
      </div>
    );
  };

  renderProvince = (address) => {
    let result = address.includes('تهران');
    // return !result ? <div className='order-province'></div> : null;
    return;
  };

  decodeHtmlEntities = (str) => {
    return str.replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec);
    });
  };

  render() {
    const { datasets, count, orderItems, pageInfo, selectedStatus, isLoading, startDateTime, endDateTime, visible } = this.state;
    const { history } = this.props;
    const orders = [
      {
        title: 'نام محصول',
        name: 'name',
        bodyRender: (data) => {
          return this.renderItemName(data);
        },
      },

      {
        title: 'قیمت',
        bodyRender: (data) => {
          return `${Number(data.price).toLocaleString('fa')} تومان`;
        },
      },
      {
        title: 'قیمت کل (تومان)',
        bodyRender: (data) => {
          return this.renderTotalOrderPrice(data);
        },
      },
      {
        title: '',
      },
    ];
    const columns = [
      {
        title: 'نام و نام خانوادگی',
        bodyRender: (data) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() =>
                this.setState({
                  orderItems: {
                    fullName: data.fullName,
                    mobileNumber: data.mobileNumber,
                    items: data.orderItems,
                    address: data.address,
                    price: Number(data.price).toLocaleString('fa'),
                    description: data.description,
                    createdAt: data.createdAt,
                  },
                })
              }>
              {this.renderProvince(data.address)}
              <Icon style={{ marginLeft: 5 }} icon={iosInformationOutline} />
              {data.fullName}
            </div>
          );
        },
      },
      {
        title: 'توضیحات',
        bodyRender: (data) => {
          return <div className='long-content'>{data.description}</div>;
        },
      },
      {
        title: 'ثبت',
        name: 'createdAt',
        bodyRender: (data) => {
          return moment(data.createdAt).format('jYYYY/jMM/jDD - HH:mm');
        },
        needSort: true,
      },
      {
        title: 'قیمت',
        bodyRender: (data) => {
          return `${Number(data.price).toLocaleString('fa')} تومان`;
        },
      },
      {
        title: 'قیمت با تخفیف',
        bodyRender: (data) => {
          return `${Number(data.priceWithDiscount).toLocaleString('fa')} تومان`;
        },
      },
      {
        title: 'وضعیت',
        bodyRender: (data) => {
          return this.renderStatus(data.status);
        },
      },
      {
        title: 'فرستنده',
        bodyRender: (data) => {
          return this.renderCourier(data.courier);
        },
      },
      {
        title: 'تاریخ تسویه',
        bodyRender: (data) => {
          return data.checkoutDate ? moment(data.checkoutDate).locale('fa').format('YYYY/M/D - HH:mm') : '---';
        },
      },
      {
        title: '',
        bodyRender: (data) => {
          return (
            <Dropdown className='table-control__container' visible={visible === data.id} onVisibleChange={(v) => this.setState({ visible: data.id })} position={DropdownPosition.AutoBottomLeft}>
              <DropdownClickTrigger>
                <DropdownButton type='primary'>عملیات‌ها</DropdownButton>
              </DropdownClickTrigger>
              <DropdownContent>
                <Menu onClick={(e, item) => this.handleMenuButton(item, data)}>
                  <MenuItem>
                    <Icon icon={printer} /> پرینت
                  </MenuItem>
                  <MenuItem>
                    <Icon icon={edit} /> ویرایش
                  </MenuItem>
                  <MenuItem>
                    <Icon icon={trash2} /> حذف
                  </MenuItem>
                  {data.status !== 2 && !data.isPaid ? (
                    <MenuItem>
                      <Icon icon={shoppingCart} /> پرداخت
                    </MenuItem>
                  ) : (
                    ''
                  )}
                </Menu>
              </DropdownContent>
            </Dropdown>
          );
        },
      },
    ];

    // <div className="table-control__container">

    //           <Button
    //             type="primary"
    //             onClick={() => history.push(`/order/${data.id}`)}
    //             style={{
    //               marginRight: data.status !== 2 && !data.isPaid ? 0 : 10,
    //             }}>
    //             <Icon icon={edit} />
    //           </Button>
    //           <Button type="danger" onClick={() => this.removeOrder(data)} style={{ marginRight: 0 }}>
    //             <Icon icon={trash2} />
    //           </Button>
    //         </div>

    return (
      <div className='animated fadeIn'>
        <Block>
          <h1 className='title'>فهرست سفارش‌ها ({count})</h1>
          <div className='row with-column'>
            <div className='column'>
              <Select
                data={[
                  { id: 1, name: 'ثبت شده' },
                  { id: 2, name: 'پرداخت شده' },
                  { id: 3, name: 'در حال ارسال' },
                  { id: 4, name: 'تحویل داده شده' },
                  { id: 5, name: 'لغو' },
                  { id: 6, name: 'ارسال برای چاپ' },
                  { id: 7, name: 'آماده ارسال' },
                  { id: 8, name: 'تحویل پست' },
                ]}
                autoWidth
                optionText='name'
                optionValue='id'
                placeholder='انتخاب نوع وضعیت'
                emptyText='هیچ آیتمی یافت نشده است.'
                onChange={this.onChangeStatus}
                value={selectedStatus}
              />
              <Input onPressEnter={this.onPressEnter} icon='search' placeholder='جستجو ...' />
              <Button className='add-btn' onClick={() => history.push('/order/add')}>
                درج سفارش
              </Button>
            </div>
            <div className='column'>
              <div className='column'>
                <div className='zent-form-control'>
                  <label className='zent-form__control-label'>از تاریخ</label>
                  <DatePicker max={enabledRange.max} isGregorian={false} timePicker={false} value={startDateTime} onChange={(startDateTime) => this.setState({ startDateTime })} className={'zent-input'} placeholder='' />
                </div>
                <div className='zent-form-control'>
                  <label className='zent-form__control-label'>تا تاریخ</label>
                  <DatePicker max={enabledRange.max} isGregorian={false} timePicker={false} value={endDateTime} onChange={(endDateTime) => this.setState({ endDateTime })} className={'zent-input'} placeholder='' />
                </div>
                <Button className='action-btn' onClick={() => this.searchByDate()}>
                  فیلتر
                </Button>
              </div>
            </div>
          </div>
        </Block>
        <Grid pageInfo={pageInfo} columns={columns} datasets={datasets} onChange={this.onChange} rowClassName={this.rowClass} emptyLabel={'هیچ سفارشی یافت نشده است.'} loading={isLoading} />
        <Portal visible={orderItems ? true : false} onClose={() => this.setState({ orderItems: null })} className='layer' style={{ background: 'rgba(0, 0, 0, 0.4)' }} useLayerForClickAway closeOnClickOutside closeOnESC blockPageScroll>
          {orderItems && (
            <div className='custom-portal__container'>
              <Grid columns={orders} datasets={orderItems.items} emptyLabel={'هیچ سفارشی یافت نشده است.'} />
              <div className='inovice-information'>
                <span>توضیحات: {orderItems.description}</span>
                <span>نام و نام خانوادگی: {orderItems.fullName}</span>
                <span>شماره تماس: {orderItems.mobileNumber}</span>
                <span>آدرس: {orderItems.address}</span>
                <span>کل مبلغ خرید: {orderItems.price} تومان</span>
                <span>تاریخ آخرین به روز رسانی: {moment(orderItems.createdAt).locale('fa').format('YYYY/M/D - HH:mm')}</span>
              </div>
            </div>
          )}
        </Portal>
      </div>
    );
  }
}

export default Orders;
