import axios from 'axios';

// const baseURL = `${process.env.NODE_ENV === 'production' ? `https://api.snb-bizi.ir` : `http://localhost:7831`}`;
const baseURL = `https://api.snb-bizi.ir`;

export const login = (data) => {
  return axios.post(`${baseURL}/auth/local`, data);
};

export const forgotPassword = (data) => {
  return axios.post(`${baseURL}/auth/forgot-password`, data);
};

export const fetchProfile = () => {
  return axios.get(`${baseURL}/users/me`);
};
