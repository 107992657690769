import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Tag, Button } from 'zent';
import moment from 'jalali-moment';
import DatePicker from 'react-datepicker2';

import { fetchOrdersByDate } from '../../services/orderService';

import Block from '../../components/common/block';
import { ExportToExcel } from '../../components/common/exportExcel';

const enabledRange = {
  max: moment(),
};

const convertToArray = (array) => {
  return _.map(array, function (value, key) {
    return { items: value };
  });
};

const Analytics = () => {
  const [items, setItems] = useState({
    latestSailedProduct: [],
  });
  const [date, setDate] = useState({
    startDateTime: null,
    endDateTime: null,
  });
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let date = moment().utcOffset(0);
    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    fetchOrdersByDate(date.toISOString(), null)
      .then((res) => {
        setLoading(false);
        let sortedByName = [];
        res.data.forEach((item) => {
          if (item.orderItems.length > 0) {
            item.orderItems.forEach((el) => {
              let index = el.name.indexOf('(');
              if (index) {
                el.name = el.name.slice(0, index).replace(/\s+/g, ' ');
              }
              sortedByName.push(el);
            });
          }
        });
        let resultSortedByName = convertToArray(
          _.groupBy(sortedByName, function (item) {
            return item.name;
          })
        );
        resultSortedByName = resultSortedByName.sort((a, b) => b.count - a.count);
        let finalResult = [];
        resultSortedByName.forEach((item) => {
          item.items.forEach((el) => {
            if (el.color) {
              el.color = el.color.replace(/ /g, '');
            }
            finalResult.push(el);
          });
          return item;
        });
        setItems({
          latestSailedProduct: finalResult,
        });
      })
      .catch((err) => setLoading(false));
  }, []);

  const searchByDate = () => {
    setLoading(true);
    fetchOrdersByDate(moment(date.startDateTime).toISOString(), moment(date.endDateTime).toISOString())
      .then((res) => {
        setLoading(false);
        let sortedByName = [];
        res.data.forEach((item) => {
          if (item.orderItems.length > 0) {
            item.orderItems.forEach((el) => {
              let index = el.name.indexOf('(');
              if (index) {
                el.name = el.name.slice(0, index).replace(/\s+/g, ' ');
              }
              sortedByName.push(el);
            });
          }
        });
        let resultSortedByName = convertToArray(
          _.groupBy(sortedByName, function (item) {
            return item.name;
          })
        );
        resultSortedByName = resultSortedByName.sort((a, b) => b.count - a.count);
        let finalResult = [];
        resultSortedByName.forEach((item) => {
          item.items.forEach((el) => {
            if (el.color) {
              el.color = el.color.replace(/ /g, '');
            }
            finalResult.push(el);
          });
          return item;
        });
        setItems({
          latestSailedProduct: finalResult,
        });
      })
      .catch((err) => setLoading(false));
  };

  const productColumns = [
    {
      title: 'ردیف',
      bodyRender: (data, config) => {
        return config.row + 1;
      },
    },
    {
      title: 'نام محصول',
      name: 'name',
    },
    {
      title: 'رنگ',
      name: 'color',
    },
    {
      title: 'XXS',
      bodyRender: (data) => {
        return data.size.value === 0 ? (
          <Tag theme='blue' outline>
            {data.orderCount}
          </Tag>
        ) : (
          ''
        );
      },
    },
    {
      title: 'XS',
      bodyRender: (data) => {
        return data.size.value === 1 ? (
          <Tag theme='blue' outline>
            {data.orderCount}
          </Tag>
        ) : (
          ''
        );
      },
    },
    {
      title: 'S',
      bodyRender: (data) => {
        return data.size.value === 2 ? (
          <Tag theme='blue' outline>
            {data.orderCount}
          </Tag>
        ) : (
          ''
        );
      },
    },
    {
      title: 'M',
      bodyRender: (data) => {
        return data.size.value === 3 ? (
          <Tag theme='blue' outline>
            {data.orderCount}
          </Tag>
        ) : (
          ''
        );
      },
    },
    {
      title: 'L',
      bodyRender: (data) => {
        return data.size.value === 4 ? (
          <Tag theme='blue' outline>
            {data.orderCount}
          </Tag>
        ) : (
          ''
        );
      },
    },
    {
      title: 'XL',
      bodyRender: (data) => {
        return data.size.value === 5 ? (
          <Tag theme='blue' outline>
            {data.orderCount}
          </Tag>
        ) : (
          ''
        );
      },
    },
    {
      title: 'XXL',
      bodyRender: (data) => {
        return data.size.value === 6 ? (
          <Tag theme='blue' outline>
            {data.orderCount}
          </Tag>
        ) : (
          ''
        );
      },
    },
    {
      title: 'مجوع فروش',
      bodyRender: (data) => {
        let total = Number(data.price) * Number(data.orderCount);
        return `${total.toLocaleString('fa')} تومان`;
      },
    },
  ];

  console.log(items.latestSailedProduct);

  return (
    <Container className='animated fadeIn'>
      <div className='items'>
        <Block>
          <div className='row with-column'>
            <div className='column'>
              <div className='zent-form-control'>
                <label className='zent-form__control-label'>از تاریخ</label>
                <DatePicker max={enabledRange.max} isGregorian={false} timePicker={false} value={date.startDateTime} onChange={(startDateTime) => setDate({ ...date, startDateTime })} className={'zent-input'} placeholder='' />
              </div>
              <div className='zent-form-control'>
                <label className='zent-form__control-label'>تا تاریخ</label>
                <DatePicker max={enabledRange.max} isGregorian={false} timePicker={false} value={date.endDateTime} onChange={(endDateTime) => setDate({ ...date, endDateTime })} className={'zent-input'} placeholder='' />
              </div>
              <Button className='action-btn' onClick={searchByDate}>
                فیلتر
              </Button>
              <ExportToExcel apiData={items.latestSailedProduct} fileName={'excel_sheets'} />
            </div>
          </div>
        </Block>
        <Row>
          <Column>
            <h2>محصولات فروخته شده بر اساس تعداد</h2>
            <Grid columns={productColumns} datasets={items.latestSailedProduct} loading={isLoading} emptyLabel={'هیچ آیتمی یافت نشده است.'} rowKey='index' />
          </Column>
        </Row>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .items {
    display: block;
    flex: 1;
    margin-left: 20px;
    &::last-child {
      margin-left: 0;
    }
  }
  h2 {
    margin: 25px 0;
    color: #fff;
    font-size: 1.3em;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Column = styled.div`
  flex: 1;
  padding: 0 10px;
`;

export default Analytics;
