import React, { useState, useEffect } from 'react';
import { Grid, Notify, Button, Portal } from 'zent';
import { withBaseIcon } from 'react-icons-kit';
import { iosInformationOutline } from 'react-icons-kit/ionicons/iosInformationOutline';
import { edit } from 'react-icons-kit/feather/edit';
import moment from 'jalali-moment';

import { fetchUsers } from '../../services/orderService';
import { useStateValue } from '../../context/state';

import Block from '../../components/common/block';

const Icon = withBaseIcon({ size: 18, style: { color: '#fff' } });

const pageInfo = {
  pageSize: 500,
  total: 0,
  current: 0,
  start: 0,
};

const ProductOrderItems = ({ history }) => {
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [orderItems, setOrderItems] = useState(null);
  const [{ sharedItems }] = useStateValue();

  useEffect(() => {
    fetchUsers()
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Notify.error('در برقراری ارتباط مشکلی به وجود آمده است، مجددا تلاش نمایید.');
      });
  }, []);

  const renderStatus = (status) => {
    switch (Number(status)) {
      case 1:
        return <span className="order-status status1">ثبت شده</span>;
      case 2:
        return <span className="order-status status2">پرداخت شده</span>;
      case 3:
        return <span className="order-status status3">در حال ارسال</span>;
      case 4:
        return <span className="order-status status4">تحویل داده شده</span>;
      case 5:
        return <span className="order-status status5">لغو</span>;
      case 6:
        return <span className="order-status status6">ارسال برای چاپ</span>;
      case 7:
        return <span className="order-status status7">آماده ارسال</span>;
      default:
        return '';
    }
  };

  const renderCourier = (id) => {
    return users.map((item) => {
      return item.courierId === Number(id) ? item.fullName : '';
    });
  };

  const renderTotalOrderPrice = (data) => {
    let { priceWithDiscount, price, orderCount } = data;
    if (priceWithDiscount && Number(priceWithDiscount) !== 0 && Number(priceWithDiscount) < Number(price)) {
      return (Number(priceWithDiscount) * Number(orderCount)).toLocaleString('fa');
    }
    return (Number(price) * Number(orderCount)).toLocaleString('fa');
  };

  const orders = [
    {
      title: 'نام محصول',
      name: 'name',
      bodyRender: (data) => {
        return `${data.name}`;
      },
    },
    {
      title: 'تعداد',
      bodyRender: (data) => {
        return data.orderCount;
      },
    },
    {
      title: 'قیمت',
      bodyRender: (data) => {
        return `${Number(data.price).toLocaleString('fa')} تومان`;
      },
    },
    {
      title: 'قیمت کل (تومان)',
      bodyRender: (data) => {
        return renderTotalOrderPrice(data);
      },
    },
    {
      title: '',
    },
  ];
  const columns = [
    {
      title: 'نام و نام خانوادگی',
      bodyRender: (data) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setOrderItems({
                fullName: data.fullName,
                mobileNumber: data.mobileNumber,
                items: data.orderItems,
                address: data.address,
                price: Number(data.price).toLocaleString('fa'),
                description: data.description,
              })
            }
          >
            <Icon style={{ marginLeft: 5 }} icon={iosInformationOutline} />
            {data.fullName}
          </div>
        );
      },
    },
    {
      title: 'توضیحات',
      bodyRender: (data) => {
        return <div className="long-content">{data.description}</div>;
      },
    },
    {
      title: 'تاریخ',
      name: 'createdAt',
      bodyRender: (data) => {
        return moment(data.createdAt).locale('fa').format('YYYY/M/D - HH:mm');
      },
      needSort: true,
    },
    {
      title: 'قیمت',
      bodyRender: (data) => {
        return `${Number(data.price).toLocaleString('fa')} تومان`;
      },
    },
    {
      title: 'قیمت با تخفیف',
      bodyRender: (data) => {
        return `${Number(data.priceWithDiscount).toLocaleString('fa')} تومان`;
      },
    },
    {
      title: 'وضعیت',
      bodyRender: (data) => {
        return renderStatus(data.status);
      },
    },
    {
      title: 'فرستنده',
      bodyRender: (data) => {
        return renderCourier(data.courier);
      },
    },
    {
      title: '',
      width: '5%',
      bodyRender: (data) => {
        return (
          <div className="table-control__container">
            <Button type="primary" onClick={() => history.push(`/order/${data.id}`)}>
              <Icon icon={edit} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="animated fadeIn">
      <Block>
        <h1 className="title">سفارشات محصول {''}</h1>
      </Block>
      <Grid pageInfo={pageInfo} columns={columns} datasets={!sharedItems ? [] : sharedItems} emptyLabel={'هیچ سفارشی یافت نشده است.'} loading={isLoading} />
      <Portal visible={orderItems ? true : false} onClose={() => setOrderItems(null)} className="layer" style={{ background: 'rgba(0, 0, 0, 0.4)' }} useLayerForClickAway closeOnClickOutside closeOnESC blockPageScroll>
        {orderItems && (
          <div className="custom-portal__container">
            <Grid columns={orders} datasets={orderItems.items} emptyLabel={'هیچ سفارشی یافت نشده است.'} />
            <div className="inovice-information">
              <span>توضیحات: {orderItems.description}</span>
              <span>نام و نام خانوادگی: {orderItems.fullName}</span>
              <span>شماره تماس: {orderItems.mobileNumber}</span>
              <span>آدرس: {orderItems.address}</span>
              <span>کل مبلغ خرید: {orderItems.price} تومان</span>
            </div>
          </div>
        )}
      </Portal>
    </div>
  );
};

export default ProductOrderItems;
