import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'zent';

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData, fileName) => {
    let array = JSON.parse(JSON.stringify(apiData)),
      result = [];
    array.forEach((item) => {
      item.size = item.size ? item.size.label : '---';
      result.push({ name: item.name, color: item.color, size: item.size, price: item.price, count: item.orderCount });
    });
    console.log('Result', result);
    const ws = XLSX.utils.json_to_sheet(result);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button type='primary' className='action-btn' onClick={(e) => exportToCSV(apiData, fileName)}>
      خروجی Excel
    </Button>
  );
};
